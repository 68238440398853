/**
 * Middleware for protected routes where a SOFT login is sufficient
 * e.g. favorite list
 */
import { useAuthStore } from "~/stores/auth.store";

const { getPath } = useRoutes();

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();

  // Initial Request to Nuxt
  if (!authStore.isLoggedIn && to.path === from.path) {
    return navigateTo({ path: getPath("checkAuth"), query: { r: to.path } });
  }

  if (!authStore.isLoggedIn) {
    authStore.redirectUrl = to.path;
    return navigateTo(getPath("login"));
  }
});
